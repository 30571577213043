<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<span class="text-h5">{{ header }}</span>
		</q-card-section>
		<q-card-section>
			<details-button
				:tooltip_content="multiple_options_tooltip_content"
				name="multiple-options"
			/>
			<q-input
				v-if="!is_multiple"
				label="Field option"
				bottom-slots
				v-model="label"
				:error="label_error"
				:error-message="label_error_msg"
				@keyup.enter="addOptionBtnHandler"
			/>
			<q-input
				v-else
				label="Field options"
				type="textarea"
				bottom-slots
				v-model="label"
				:error="label_error"
				:error-message="label_error_msg"
			/>
		</q-card-section>
		<q-card-actions>
			<q-btn
				:label="is_multiple ? 'Add Options' : 'Add Option'"
				color="primary"
				class="full-width"
				@click="addOptionBtnHandler"
			/>
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
export default {
	name: 'AddOptionDialogCard',
	components: {
		DetailsButton
	},
	props: {
		field: {
			type: Object,
			required: true,
		},
		is_multiple: Boolean
	},
	data() {
		return {
			label_error: false,
			label_error_msg: '',
			label: '',
			multiple_options_tooltip_content: `Enter 1 option per line. Blank lines will be ignored.`
		}
	},
	computed: {
		header() {
			if (this.is_multiple){
				return `Add new options for ${this.field.label}`
			} else {
				return `Add new option for ${this.field.label}`
			}
		}
	},
	methods: {
		...mapActions(['createFieldOption', 'getDataFields', 'fetchUser']),
		async addOptionBtnHandler() {
			if(!this.is_multiple) {
				await this.addFieldOption(this.label)
			} else {
					let no_value_options = 0
					if (String(this.label) != "undefined"){
						var array = this.label.split('\n');
						for (var i in array){
							no_value_options = array[i] == '' ? no_value_options + 1 : no_value_options
							await this.addFieldOption(array[i], true)
						}
						if (no_value_options == array.length){
							this.$q.notify({
								timeout: 6700,
								message: 'Please enter value for the option.',
								actions: [
									{
										label: 'Dismiss',
										color: 'primary',
									},
								],
							})
							this.$emit('close')
						}
					}
			}
			await this.fetchUser()
		},
		async addFieldOption(label, is_multiple) {
			this.$q.loading.show({
				delay: 400,
			})
			if (!label.length && is_multiple){
				return
			}
			const response = await this.createFieldOption({
				option_data: {
					field: this.field.id,
					value: label,
				},
				cg_id: parseInt(this.$route.params.id),
			})
			this.$q.loading.hide()
			this.$emit('close')
			if (response.error) {
				this.$q.notify({
					timeout:  6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify('Field option added successfully.')
		}
	},
}
</script>

<style></style>
