<template>
	<q-card>
		<q-card-section class="text-center">
			<div class="q-mb-sm">
				<div v-if="type === 'field_type_change'">
					Are you sure you want to change {{ label }}'s type?
				</div>
				<div v-else>
					Are you sure you want to delete {{ type }}	<b>{{ label }}</b>?
				</div>
			</div>
			<div v-if="scenarios.length">
				<span v-if="type === 'option'">
					Removing this option would affect the following message templates and
					would disable the sending rules. Continue?
				</span>
				<span v-else-if="type === 'field'">
					Removing this field would affect the following message templates.
					Continue?
				</span>
				<span v-else-if="type === 'field_type_change'">
					Changing this field would affect the following message templates.
					Continue?
				</span>
			</div>
		</q-card-section>
		<q-card-section
			style="max-height: 50vh"
			class="scroll"
			v-if="scenarios.length"
		>
			<q-list bordered separator>
				<q-item v-for="scenario in scenarios" :key="scenario.id">
					<q-item-section>
						<q-btn
							flat
							color="primary"
							:to="{ name: 'Edit Message', params: { id: scenario.id } }"
							:label="scenario.name"
						/>
					</q-item-section>
				</q-item>
			</q-list>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn
				flat
				:label="type === 'field_type_change' ? 'Confirm' : 'Delete'"
				color="primary"
				@click="confirmBtnHandler"
			/>
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'WarningDialogCard',
	props: {
		label: {
			type: String,
			required: true,
		},
		id: {
			type: Number,
			required: true,
		},
		cg_id: {
			type: Number,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		scenarios: Array,
		field_data: Object,
		field_index: Number,
	},
	methods: {
		...mapActions([
			'deleteFieldOption',
			'deleteField',
			'updateField',
			'updateRemoveOptions',
		]),
		async confirmBtnHandler() {
			let action = null
			this.$q.loading.show({
				delay: 400
			})
			switch (this.type) {
				case 'field':
					action = 'deleteField'
					break
				case 'option':
					action = 'deleteFieldOption'
					break
				case 'field_type_change':
					action = 'updateField'
					break
			}
			let response = null
			if (action === 'updateField') {
				response = await this[action]({
					data: this.field_data,
					cg_id: this.cg_id,
				})
			} else {
				response = await this[action]({
					cg_id: this.cg_id,
					id: this.id,
				})
			}
			this.$q.loading.hide()
			this.$emit('close')
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				if (
					response.error_message ===
					'Please remove the options for the field first.'
				) {
					this.updateRemoveOptions({
						index: this.field_index,
						values: {
							error: true,
							open_list: true,
						},
					})
				}
				return
			}
			let successMsg = null
			switch (this.type) {
				case 'field':
					successMsg = 'The field was deleted successfully.'
					break
				case 'option':
					successMsg = 'The field option was deleted successfully.'
					break
				case 'field_type_change':
					successMsg = 'The field type has been changed successfully.'
			}
			this.$q.notify(successMsg)
		},
	},
}
</script>

<style></style>
