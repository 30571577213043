<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Data fields: {{ cg.name }}
		</h1>
		<!-- Cg Data Fields list -->
		<cg-data-field
			v-for="(field, index) in data_fields"
			:key="field.id"
			:field="field"
			:index="index"
			@deleteOption="displayDeleteOptionDialog"
			@deleteField="displayDeleteFieldDialog"
			@addOption="displayAddOptionDialog"
			@addMultipleOptionS="displayAddMultipleOptionsDialog"
			@relatedFieldTypeUpdate="displayFieldTypeChangeDialog"
		/>
		<!-- !Cg Data Fields list -->

		<!-- Add field dialog -->
		<q-dialog v-model="add_field_dialog">
			<add-field-dialog-card
				:options="field_type_options"
				label='field'
				@close="add_field_dialog = false"
			/>
		</q-dialog>
		<!-- !Add field dialog -->

		<!-- Add option dialog -->
		<q-dialog v-model="add_option_dialog">
			<add-option-dialog-card
				:field="pending_add_option_field"
				@close="add_option_dialog = false"
			/>
		</q-dialog>
		<!-- !Add option dialog -->

		<q-dialog v-model="add_multiple_options_dialog">
			<add-option-dialog-card
				:field="pending_add_option_field"
				:is_multiple="true"
				@close="add_multiple_options_dialog = false"
			/>
		</q-dialog>

		<!-- Warning dialog -->
		<q-dialog v-model="warning_dialog" persistent>
			<warning-dialog-card
				:label="warning_dialog_label"
				:type="warning_dialog_type"
				:id="warning_dialog_id"
				:cg_id="parseInt($route.params.id)"
				:scenarios="warning_dialog_related_scenarios"
				:field_data="warning_dialog_field_data"
				:field_index="warning_dialog_field_index"
				@close="closeWarningDialog"
			/>
		</q-dialog>
		<!-- !Warning dialog -->

		<!-- FAB -->
		<div class="field-fab-wrapper">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="add"
				label="Add Field"
				rounded
				@click="() => (add_field_dialog = true)"
			/>
			<q-btn
				v-else
				size="lg"
				color="primary"
				icon="add"
				round
				@click="() => (add_field_dialog = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add Field
				</q-tooltip>
			</q-btn>
		</div>
		<!-- !FAB -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CgDataField from '@/components/datasets/edit/data/CgDataField'
import WarningDialogCard from '@/components/datasets/edit/data/WarningDialogCard'
import AddOptionDialogCard from '@/components/datasets/edit/data/AddOptionDialogCard'
import AddFieldDialogCard from '@/components/datasets/utils/AddFieldDialogCard'
export default {
	name: 'Data',
	components: {
		CgDataField,
		WarningDialogCard,
		AddOptionDialogCard,
		AddFieldDialogCard,
	},
	data() {
		return {
			add_field_dialog: false,
			add_option_dialog: false,
			add_multiple_options_dialog: false,
			pending_add_option_field: {},
			warning_dialog: false,
			warning_dialog_id: null,
			warning_dialog_type: null,
			warning_dialog_label: null,
			warning_dialog_field_index: null,
			warning_dialog_field_data: {},
			warning_dialog_option_data: {},
			warning_dialog_related_scenarios: [],
			field_type_options: [
				{
					label: 'Singleselect',
					value: 'SINGLESELECT'
				},
				{
					label: 'Multiselect',
					value: 'MULTISELECT'
				},
				{
					label: 'Numeric',
					value: 'NUMERIC'
				},
				{
					label: 'Text',
					value: 'TEXT'
				},
				{
					label: 'Postcode',
					value: 'POSTCODE'
				},
			],
		}
	},
	computed: {
		...mapGetters(['data_fields']),
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	methods: {
		...mapActions([
			'getDataFieldOptionRelatedScenarios',
			'getDataFieldRelatedScenarios',
		]),
		closeWarningDialog() {
			this.warning_dialog = false
			this.warning_dialog_id = null
			this.warning_dialog_type = null
			this.warning_dialog_label = null
			this.warning_dialog_field_index = null
			this.warning_dialog_related_scenarios = []
			this.warning_dialog_field_data = {}
			this.warning_dialog_option_data = {}
		},
		async displayDeleteOptionDialog(option) {
			const response = await this.getDataFieldOptionRelatedScenarios(option.id)
			if (response.results) {
				this.warning_dialog_related_scenarios = response.results.scenarios
			}
			this.warning_dialog_id = option.id
			this.warning_dialog_type = 'option'
			this.warning_dialog_label = option.label
			this.warning_dialog = true
		},
		async displayDeleteFieldDialog(data) {
			const response = await this.getDataFieldRelatedScenarios(data.field.id)
			if (response.results) {
				this.warning_dialog_related_scenarios = response.results.scenarios
			}
			this.warning_dialog_id = data.field.id
			this.warning_dialog_type = 'field'
			this.warning_dialog_label = data.field.label
			this.warning_dialog_field_index = data.index
			this.warning_dialog = true
		},
		displayFieldTypeChangeDialog(data) {
			this.warning_dialog_id = data.field.id
			this.warning_dialog_type = 'field_type_change'
			this.warning_dialog_label = data.field.label
			this.warning_dialog_field_data = data.field
			this.warning_dialog_field_index = data.index
			this.warning_dialog_related_scenarios = data.scenarios
			this.warning_dialog = true
		},
		displayAddOptionDialog(field) {
			this.pending_add_option_field = field
			this.add_option_dialog = true
		},
		displayAddMultipleOptionsDialog(field) {
			this.pending_add_option_field = field
			this.add_multiple_options_dialog = true
		}
	},
	created() {
		this.field_type_options.sort((a, b) => a.label.localeCompare(b.label))
	}
}
</script>

<style></style>
