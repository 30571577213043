import { render, staticRenderFns } from "./CgDataField.vue?vue&type=template&id=1e4e1024&scoped=true&"
import script from "./CgDataField.vue?vue&type=script&lang=js&"
export * from "./CgDataField.vue?vue&type=script&lang=js&"
import style0 from "./CgDataField.vue?vue&type=style&index=0&id=1e4e1024&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e4e1024",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QToggle,QTooltip,QBtn,QSeparator});
