<template>
	<div>
		<q-checkbox v-model="val" :label="option.label" :disable="disable" />
	</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'InheritedDataFieldOption',
	props: {
		option: {
			type: Object,
			required: true,
		},
		disable: Boolean
	},
	computed: {
		val: {
			get() {
				return this.option.value
			},
			async set(val) {
				let data = {
					option_data: {
						customer_group: parseInt(this.$route.params.id),
						option_id: this.option.id,
						value: val,
					},
					field_id: this.option.field_id,
				}
				this.$q.loading.show({
					delay: 400,
				})
				let response = await this.excludeFieldOption(data)
				if (response.error) {
					this.$q.notify({
						timeout:  6700,
						message: response.error_message,
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
				this.$q.loading.hide()
				this.$q.notify('Field option updated successfully.')
			},
		},
	},
	methods: {
		...mapActions(['excludeFieldOption']),
	},
}
</script>
