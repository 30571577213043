<template>
	<!--
	Prevent event bubbling because we trigger it programatically
	from the parent component (avoid double clicks)
-->
	<div @click.stop>
		<div class="row justify-between">
			<div class="row items-center">
				<span class="q-mr-sm">{{ value }}</span>
				<q-icon name="edit" />
			</div>
			<q-btn
				flat
				icon="delete"
				color="primary"
				@click.stop="deleteBtnHandler(false)"
			/>
		</div>
		<q-popup-edit v-model="value" auto-save>
			<template v-slot="scope">
				<q-input
					v-model="scope.value"
					dense
					autofocus
					@keyup.enter="scope.cancel"
				>
					<template v-slot:after>
						<q-btn
							flat
							icon="delete"
							color="primary"
							@click="deleteBtnHandler(scope)"
						/>
					</template>
				</q-input>
			</template>
		</q-popup-edit>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'NonInheritedDataFieldOption',
	props: {
		option: {
			type: Object,
			required: true,
		},
	},
	computed: {
		value: {
			get() {
				return this.option.label
			},
			async set(val) {
				this.$q.loading.show({
					delay: 400,
				})
				const data = {
					option_data: {
						field: this.option.field_id,
						id: this.option.id,
						value: val,
					},
					cg_id: parseInt(this.$route.params.id)
				}
				const response = await this.updateFieldOption(data)
				this.$q.loading.hide()
				if (response.error) {
					this.$q.notify({
						timeout: 6700,
						message: response.error_message,
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
				this.$q.notify('Field option updated successfully.')
				await this.fetchUser()
			},
		},
	},
	methods: {
		...mapActions([
			'updateFieldOption',
			'getDataFields',
			'fetchUser',
		]),
		deleteBtnHandler(scope) {
			if (scope) {
				scope.cancel()
			}
			this.$emit('deleteOption', this.option)
		},
	},
}
</script>

<style></style>
