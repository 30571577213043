<template>
	<q-expansion-item
		ref="list"
		class="text-left"
		expand-separator
		icon="edit_note"
		label="Field Options"
	>
		<q-btn
			class="q-my-md"
			color="primary"
			flat
			icon="add"
			label="Add New Option"
			@click="() => $emit('addOption', field)"
		/>
		<q-btn
			class="q-my-md"
			color="primary"
			flat
			icon="add"
			label="Add Multiple Options"
			@click="() => $emit('addMultipleOptionS', field)"
		/>
		<q-list :bordered="options && options.length ? true : false">
			<q-item
				clickable
				v-for="(option, index) in options"
				:key="option.id"
				@click="openFieldOptionEdit(index)"
			>
				<q-item-section>
					<non-inherited-data-field-option
						:option="option"
						ref="field_options"
						v-on="$listeners"
					/>
				</q-item-section>
			</q-item>
		</q-list>
	</q-expansion-item>
</template>

<script>
import NonInheritedDataFieldOption from '@/components/datasets/edit/data/NonInheritedDataFieldOption'
export default {
	name: 'NonInheritedOptionsList',
	components: {
		NonInheritedDataFieldOption,
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		field: {
			type: Object,
			required: true,
		},
		open: Boolean,
	},
	methods: {
		// Propagate click
		openFieldOptionEdit(index) {
			this.$refs.field_options[index].$el.click()
		},
	},
	watch: {
		open(val) {
			if (val) {
				this.$refs.list.show()
			}
		},
	},
}
</script>

<style></style>
