<template>
	<q-expansion-item
		v-if="options.length"
		class="text-left"
		expand-separator
		icon="checklist"
		label="Field Options"
		:default-opened="open"
	>
		<inherited-data-field-option
			v-for="option in options"
			:key="option.id"
			:option="option"
			:disable="disable"
		/>
	</q-expansion-item>
</template>

<script>
import InheritedDataFieldOption from '@/components/datasets/edit/data/InheritedDataFieldOption'
export default {
	name: 'InheritedOptionsList',
	components: {
		InheritedDataFieldOption
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		open: Boolean,
		disable: Boolean,
	},
}
</script>
