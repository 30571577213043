<template>
	<div
		:class="{
			'device-wrapper': true,
			'bg-grey-2': true,
			'q-mb-md': true,
			'rounded-borders': true,
		}"
	>
		<!-- Field components -->
		<div class="row q-py-md q-px-sm justify-between relative-position">
			<div class="col-12 col-md-5 q-px-sm">
				<q-input
					label="Field Label"
					outlined
					bottom-slots
					v-model="field_label"
					:error="label_input_error"
					:error-message="label_input_error_msg"
					:disable="field.is_inherited || reports_recipients_field"
				/>
			</div>
			<div class="col-12 col-md-5 q-px-sm">
				<q-select
					label="Field type"
					v-model="field_type"
					:disable="field.is_inherited || reports_recipients_field"
					:options="field_type_options"
				/>
			</div>
			<div class="col-12 col-md-2 text-right">
				<div>
					<q-toggle
						v-model="personally_identifiable_information"
						label="Personal Data"
						left-label
						:disable="field.is_inherited || reports_recipients_field"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Personally Identifiable Information
						</q-tooltip>
					</q-toggle>
				</div>
				<div>
					<q-toggle
						v-model="protected_on_import"
						label="Protection"
						left-label
						:disable="field.is_inherited || reports_recipients_field"
					>
						<q-tooltip anchor="top middle" :delay="500"
							>Protect from deletion via import</q-tooltip
						>
					</q-toggle>
				</div>
			</div>
			<div
				v-if="!field.is_inherited && !reports_recipients_field"
				class="col-12 q-mt-md"
			>
				<div class="row justify-end q-pt-sm device-btns-wrapper">
					<q-btn
						outline
						color="primary"
						class="q-mr-md"
						icon-right="save"
						label="Save"
						@click="saveField"
						:disable="disable"
					/>
					<q-btn
						outline
						color="primary"
						icon-right="delete"
						label="Delete"
						@click="deleteBtnHandler"
						:disable="disable"
					/>
				</div>
			</div>
		</div>
		<!-- !Field components -->
		<!-- Options components -->
		<div
			v-if="
				['SINGLESELECT', 'MULTISELECT'].includes(field_type) ||
				field_options.length
			"
		>
			<q-separator />
			<div class="row q-pa-sm justify-between relative-position">
				<div class="col-12">
					<!-- Options list if inherited -->
					<inherited-options-list
						v-if="field.is_inherited || reports_recipients_field"
						:options="field_options"
						:open="reports_recipients_field"
						:disable="reports_recipients_field"
					/>
					<!-- !Options list if inherited -->

					<!-- Options list if not inherited -->
					<non-inherited-options-list
						v-if="!field.is_inherited && !reports_recipients_field"
						v-on="$listeners"
						:options="field_options"
						:field="field"
						:open="remove_options[index].open_list"
						:class="{ 'error-options-list': remove_options[index].error }"
					/>
					<!-- !Options list if not inherited -->
				</div>
			</div>
		</div>
		<!-- !Options components -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InheritedOptionsList from '@/components/datasets/edit/data/InheritedOptionsList'
import NonInheritedOptionsList from '@/components/datasets/edit/data/NonInheritedOptionsList'
export default {
	name: 'CgDataField',
	components: {
		InheritedOptionsList,
		NonInheritedOptionsList,
	},
	props: {
		field: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		disable: Boolean,
	},
	data() {
		return {
			label_input_error: false,
			label_input_error_msg: 'This field is required.',
			field_type_options: [
				'SINGLESELECT',
				'MULTISELECT',
				'NUMERIC',
				'TEXT',
				'POSTCODE',
			].sort(),
			field_vals: {},
			open_non_inherited_options_list: false,
			remove_options_error: false,
		}
	},
	computed: {
		...mapGetters(['data_fields', 'remove_options']),
		reports_recipients_field() {
			return this.field.label === 'Reports Recipients'
		},
		field_options() {
			if (this.field.options) {
				return this.field.options.map((option) => {
					return {
						label: option.value,
						id: option.id,
						value: !this.field.excluded_options.includes(option.id),
						field_id: this.field.id,
					}
				})
			}
			return []
		},
		field_label: {
			get() {
				return this.field.label
			},
			set(val) {
				this.field_vals.label = val
				this.updateDataFields()
			},
		},
		field_type: {
			get() {
				return this.field.field_type
			},
			async set(val) {
				this.field_vals.field_type = val
				const response = await this.getDataFieldRelatedScenarios(this.field.id)
				if (response.results) {
					this.$emit('relatedFieldTypeUpdate', {
						index: this.index,
						field: this.field_vals,
						scenarios: response.results.scenarios,
					})
				} else {
					this.updateDataFields()
				}
			},
		},
		personally_identifiable_information: {
			get() {
				return this.field.personally_identifiable_information
			},
			set(val) {
				this.field_vals.personally_identifiable_information = val
				this.updateDataFields()
			},
		},
		protected_on_import: {
			get() {
				return this.field.protected_on_import
			},
			set(val) {
				this.field_vals.protected_on_import = val
				this.updateDataFields()
			},
		},
	},
	methods: {
		...mapActions([
			'updateField',
			'getDataFieldRelatedScenarios',
			'updateRemoveOptions',
			'fetchUser',
		]),
		updateDataFields() {
			let data_fields = JSON.parse(JSON.stringify(this.data_fields))
			if (!this.field_vals.options) {
				this.field_vals.options = []
			}
			data_fields[this.index] = JSON.parse(JSON.stringify(this.field_vals))
			this.$store.dispatch('updateDataFields', data_fields)
		},
		async saveField() {
			this.$q.loading.show({
				delay: 400,
			})
			const cg_id = parseInt(this.$route.params.id)
			if (!this.field_vals.label) {
				this.label_input_error = true
				return
			}
			this.label_input_error = false
			this.label_input_error_msg = 'This field is required.'
			const response = await this.updateField({
				data: this.field_vals,
				cg_id: cg_id,
			})
			this.$q.loading.hide()
			if (!response.error) {
				this.$q.notify('The field was saved successfully.')
				await this.fetchUser()
				return
			}
			if (
				response.error_message ===
				'Please remove the options for the field first.'
			) {
				this.$q.notify({
					timeout:  6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.updateRemoveOptions({
					index: this.index,
					values: {
						error: true,
						open_list: true,
					},
				})
				this.open_non_inherited_options_list = true
				this.remove_options_error = true
			} else {
				this.label_input_error_msg = response.error_message
				this.label_input_error = true
			}
		},
		deleteBtnHandler() {
			this.$emit('deleteField', {
				index: this.index,
				field: this.field,
			})
		},
	},
	created() {
		this.field_vals = JSON.parse(JSON.stringify(this.field))
		if (!this.field_vals.options) {
			this.field_vals.options = []
		}
	},
	updated() {
		this.field_vals = JSON.parse(JSON.stringify(this.field))
		if (!this.field_vals.options) {
			this.field_vals.options = []
		}
	},
}
</script>

<style lang="scss" scoped>
.device-wrapper {
	border: 1px solid $grey-4;
}
.error-options-list {
	border: 1px solid $negative;
}
</style>
